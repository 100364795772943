import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
// components
import Layout from "../components/layout"
import TitleLeft from "../components/molecules/title_left"
import Paragraph from "../components/atoms/paragraph"
import SEO from "../components/seo"

const StyledNewsPost = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-template-columns: inherit;
  grid-template-rows: inherit;
  padding: 15px 0;
`

const OuterWrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  grid-column-end: -2;
  grid-column-start: 2;
  min-height: calc(100vh - 169px);
  padding: 25px;

  @media (max-width: 767px) {
    min-height: calc(100vh - 137px);
  }
`

const InnerWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const NewsPost = ({
  data,
  location
}) => {
  const {
    title,
    date,
    formattedDate,
    content
  } = data.microcmsNews
  return (
    <Layout location={location}>
      <StyledNewsPost>
        <OuterWrapper>
          <article>
            <TitleLeft
              level={1}
              fontSize="20px"
              heading={title}
            />
            <InnerWrapper>
              <aside>
                <time
                  dateTime={date}
                  style={{
                    fontSize: "15px",
                    lineHeight: 1.5
                  }}
                >
                  {formattedDate}
                </time>
                <Paragraph
                  background="#e7e7eb"
                  fontSize="small"
                  padding="5px 0"
                  textAlign="center"
                  paragraph="お知らせ"
                />
              </aside>
            </InnerWrapper>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: `${content}` }}
            />
          </article>
        </OuterWrapper>
      </StyledNewsPost>
    </Layout>
  )
}

export default NewsPost

export const query = graphql`
  query($newsId: String!) {
    microcmsNews(newsId: { eq: $newsId }) {
      title
      content
      date
      formattedDate: date(formatString: "YYYY年MM月DD日")
    }
  }
`

export const Head = ({
  data,
  location
}) => {
  const {
    content,
    title
  } = data.microcmsNews
  const description = content.replace(/<.+?>/g, "")
  return (
    <SEO
      title={`${title} | お知らせ | ブログ`}
      description={
        description.length <= 70
          ? description
          : `${description.slice(0, 70)}…`
      }
      pathname={location.pathname}
    />
  )
}
